<template>
  <div v-loading="loading">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="title_right"
          ><SearchUserDate
            :type="'PersonageHuorHistogram'"
            :introductionType="'staffId'"
            :width="'100'"
            :yearIsShow="true"
            :title="titleYear"
            :list="dictData.yearList"
            :twoValue="sectionInfo.projectMember"
            v-model="sectionInfo.personageHuorYear"
            @change="onPersonageHuorHistogramChange"
        /></span>
        <span class="title_right"
          ><SearchUserDate
            :title="'员工：'"
            :width="'100'"
            :type="'PersonageHuorHistogram'"
            :introductionType="'staffId'"
            :clearable="true"
            :filterable="true"
            :list="projectMemberlist"
            :yearValue="sectionInfo.personageHuorYear"
            v-model="sectionInfo.projectMember"
            @change="onPersonageHuorHistogramChange"
        /></span>
        <el-button class="title_right" type="primary" size="small" @click="isDialog = true"
          >数据来源说明</el-button
        >
      </div>
      <PersonageHourHistogram :personageHuorList="personageHuorList" />
    </el-card>

    <el-dialog width="75%" title="数据来源说明" :visible.sync="isDialog">
      <div style="padding: 5px">
        <img src="@/assets/项目统计-个人项目工时统计.png" alt="数据来源说明" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    SearchUserDate: () => import('../searchUserDate.vue'),
    PersonageHourHistogram: () => import('./personageHourHistogram.vue'),
  },
  data() {
    return {
      loading: false, // 加载
      projectList: [], // 项目数组
      dictData: {
        yearList: [],
      },
      personageHuorList: [], // 个人工时统计
      sectionInfo: {
        // 条件筛选
        // 年份
        personageHuorYear: null,
        projectMember: '', // 项目成员
      },
      projectMemberlist: [], //项目成员
      titleYear: '统计时间：',
      isDialog: false,
      imgUrl: '',
    }
  },
  provide() {
    return {}
  },
  computed: {},
  created() {
    this.getType()
  },
  filters: {},
  watch: {},
  mounted() {},
  methods: {
    onPersonageHuorHistogramChange(arr) {
      this.personageHuorList = arr
    },

    getType() {
      this.loading = true
      this.$api.dict
        .listSysDictData('STATISTICS_YEAR', true)
        .then(async res => {
          this.dictData.yearList = res.data
          const { dictVal = '' } = res.data[0] || null
          this.sectionInfo.personageHuorYear = dictVal
          const queryPersonalProjectWorkHourSummary =
            await this.$api.statistics.queryPersonalProjectWorkHourSummary(`date=${dictVal}`)
          this.personageHuorList =
            queryPersonalProjectWorkHourSummary.data?.projectWorkHourResponseVo
              ?.personProjectWorkHourList || []
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })

      this.$api.project
        .getProjectMember()
        .then(res => {
          this.projectMemberlist = res.data.map(v => ({
            id: v.id,
            dictName: v.userName,
            dictVal: v.id,
          }))
          this.projectMemberlist.unshift({
            id: '1',
            dictName: '全部',
            dictVal: '',
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

<style scoped lang="scss">
.title_fw {
  font-size: 100%;
  font-weight: 700;
  color: #000;
}
.title_right {
  float: right;
  margin-left: 8px;
  font-size: 12px;
}
</style>
